let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Damien ANSELMI",
    "role": "Fullstack Javascript Developer Node/React ",
    "linkedinId":"Your LinkedIn Id",
    "skypeid": "Your skypeid",
    "roleDescription": "I like dabbling in various parts of fullStack development and like to learn about new technologies, read technical articles or simply play games in my free time.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/damien-anselmi-22549198//",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"http://github.com/worms19",
          "className":"fa fa-github"
        }
      ],
    "aboutme":"I am currently a pre-final year student at The LNM Institute of Information Technology and pursuing my B.Tech from here. I am a self taught Full Stack Web Developer, currently diving deeper into Machine Learning. I believe that to be successful in life, one needs to be obsessive with their dreams and keep working towards them.",
    "address":"france",
    "website":"https://rbhatia46.github.io",
    "education":[
      {
        "UniversityName":"The LNM Insitute of Information Technology",
        "specialization":"Some specialization",
        "MonthOfPassing":"Aug",
        "YearOfPassing":"2020",
        "Achievements":"Some Achievements"
      },
      {
        "UniversityName":"Some University",
        "specialization":"Some specialization",
        "MonthOfPassing":"Jan",
        "YearOfPassing":"2018",
        "Achievements":"Some Achievements"
      }
    ],
    "work":[
      {
        "CompanyName":"SOLENT",
        "specialization":"Développeur FullStack",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2018",
        "Achievements":"Projet de refonte d’un système d’information pour la gestion de l’activité des collaborateurs d’une société de service en ingénierie :\n" +
            "Développement JavaScript (NodeJs, ReactJs, Loopback)  \n" +
            "Ajout de composants front-end, de fonctionnalités API\n" +
            "Ecriture et implémentations des tests unitaires et fonctionnels \n" +
            "Assistance Client\n" +
            "Maintien en conditions opérationnelles des applications de gestion des activité du consultant\n"
      },
      {
        "CompanyName":"SOPRA STERIA",
        "specialization":"Développeur FullStack",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2018",
        "Achievements":`Maintien et développement des applications de l’OFII \n (Office Français de l’Immigration et de l’Intégration) \n Développement Java, Javascript, Apache Struts \n Maintien en conditions opérationnelles Gestion d’environnement serveur \n Assistance Client \n Environnement : Windows Server, Java 6, Apache Struts, OracleSQL`
      },
    ],
    "skillsDescription":"Your skills here",
    "skills":[
        {   "skillType":"Language",
            "skillList": "JavaScript,  Java, C"
        },
        {
            "skillType":"Os",
            "skillList": "Windows, Linux"
        },
        {
            "skillType":"Tools",
            "skillList": "IntelliJ, VScode , Eclipse, npm"
        },
        {
            "skillType":"RDBMS",
            "skillList": "MongoDB, MySQL, SQLite"
        },
        {
            "skillType":"Web",
            "skillList": "Reactjs, Nodejs, Loopback, Express, Docker, npm"
        },
        {
            "skillType":"Methods",
            "skillList": "Agile, V model"
        },
        {
            "skillType":"Version control",
            "skillList": "GIT, SVN, Fork, GitKraken"
        },
    ],
    "portfolio":[
      {
        "name":"Http Streaming server: ",
        "url":"https://github.com/Web-Multi-Media/HttpStreamingServer",
        "description":"This project is a homemade netflix made with Django REST and REACT. It handles video input on the backend side, automatic classification and display",
      },
      {
        "name":"Baron crane: ",
        "url":"https://github.com/worms19/react-baron",
        "url2":"http://baroncrane.com",
        "description":"this was my first solo project: a fully responsive web page to promote a local French band using React/node mongo/graphQl",
      },
        {
        "name":"Covideo game: ",
        "url":"https://github.com/Web-Multi-Media/covideo-game",
        "description":"during the isolation we decided to use our free time to build a social game using web sockets, node js and react",
      },

    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData